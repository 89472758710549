import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ensurancePipeById',
  standalone: true,
})
export class EnsurancePipeById implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'FIRE':
        return 'Incendios y lineas aliadas';
      case 'TRANSPORT':
        return 'Transporte';
      case 'CATASTROPHE':
        return 'Catástrofes';
      case 'OTHERS':
        return 'Otros';
      default:
        return 'Ninguno';
    }
  }
}
