import { UploadHttp } from './http/upload.http';
import { NgModule } from '@angular/core';

import { OnlyNumberDirective } from './directives/only-number.directive';
import { ValidateSpaceDirective } from './directives/validate-space.directive';
import { CofaceFormDirective } from './directives/reactive-form.directive';
import { NumberDirective } from './directives/number.directive';
import { UploadOperation } from './operations/upload.operations';
import { ReactiveFileDirective } from './directives/reactive-file.directive';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { ChargePipe, CurrencyPipe, NameCountryPipe, SystemPipe } from './pipe';
import { AddPlusSignDirective } from './directives/addPlusSign.directive';
import { OnlyLettersDirective } from './directives/only-letter.directive';

@NgModule({
  declarations: [
    OnlyNumberDirective,
    ValidateSpaceDirective,
    CofaceFormDirective,
    NumberDirective,
    ReactiveFileDirective,
    AddPlusSignDirective,
    NameCountryPipe,
    SystemPipe,
    ChargePipe,
    CurrencyPipe,
    OnlyLettersDirective
  ],
  imports: [DynamicDialogModule],
  exports: [
    OnlyNumberDirective,
    OnlyLettersDirective,
    ValidateSpaceDirective,
    CofaceFormDirective,
    NumberDirective,
    ReactiveFileDirective,
    AddPlusSignDirective,
    NameCountryPipe,
    SystemPipe,
    ChargePipe,
    CurrencyPipe,
  ],
  providers: [UploadHttp, UploadOperation, DialogService],
})
export class CofaceCommonsModule {}
