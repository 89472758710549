import { CofaceBuilder } from 'projects/ui-coface/src/app/commons/model/coface.builder';
import {
  IResponsePendingRequest,
  IResponsePendingRequestV2,
} from '../interface/pending-request.interface';
import _moment from 'moment';
import {
  ICountry,
  ILanguages,
} from 'projects/ui-coface/src/app/auth/commons/interface/auth.interface';
import { InfoSupplierPayment } from '../../../commons/homologacion-interface';
export class ListPendingRequest extends CofaceBuilder {
  private legalName: string;
  public get LegalName(): string {
    return this.legalName;
  }
  public set LegalName(value: string) {
    this.legalName = value;
  }
  private dateRequest: string;
  public get DateRequest(): string {
    return this.dateRequest;
  }
  public set DateRequest(value: string) {
    this.dateRequest = value;
  }
  private homologationId: number;
  public get HomologationId(): number {
    return this.homologationId;
  }
  public set HomologationId(value: number) {
    this.homologationId = value;
  }

  private hasAgreed: boolean;
  public get HasAgreed(): boolean {
    return this.hasAgreed;
  }
  public set HasAgreed(value: boolean) {
    this.hasAgreed = value;
  }

  private conditionId: number;
  public get ConditionId(): number {
    return this.conditionId;
  }
  public set ConditionId(value: number) {
    this.conditionId = value;
  }

  private nationalID: string;
  public get NationalID(): string {
    return this.nationalID;
  }
  public set NationalID(value: string) {
    this.nationalID = value;
  }

  private conditionDescription: string;
  public get ConditionDescription(): string {
    return this.conditionDescription;
  }
  public set ConditionDescription(value: string) {
    this.conditionDescription = value;
  }

  private isPayed: boolean;
  public get IsPayed(): boolean {
    return this.isPayed;
  }
  public set IsPayed(value: boolean) {
    this.isPayed = value;
  }
  private isPaymentVerified: boolean;
  public get IsPaymentVerified(): boolean {
    return this.isPaymentVerified;
  }
  public set IsPaymentVerified(value: boolean) {
    this.isPaymentVerified = value;
  }

  private country: ICountry;
  public get Country(): ICountry {
    return this.country;
  }
  public set Country(value: ICountry) {
    this.country = value;
  }

  private countryCustomer: ICountry;
  public get CountryCustomer(): ICountry {
    return this.countryCustomer;
  }
  public set CountryCustomer(value: ICountry) {
    this.countryCustomer = value;
  }

  private nameClient: string;
  public get NameClient(): string {
    return this.nameClient;
  }
  public set NameClient(value: string) {
    this.nameClient = value;
  }

  private nameSupplier: string;
  public get NameSupplier(): string {
    return this.nameSupplier;
  }
  public set NameSupplier(value: string) {
    this.nameSupplier = value;
  }

  private officeId: number;
  public get OfficeId(): number {
    return this.officeId;
  }
  public set OfficeId(value: number) {
    this.officeId = value;
  }

  private isPayment: InfoSupplierPayment;
  public get IsPayment(): InfoSupplierPayment {
    return this.isPayment;
  }
  public set IsPayment(value: InfoSupplierPayment) {
    this.isPayment = value;
  }

  private languageProcess: ILanguages;
  public get LanguageProcess(): ILanguages {
    return this.languageProcess;
  }
  public set LanguageProcess(value: ILanguages) {
    this.languageProcess = value;
  }

  private formId: number;
  public get FormId(): number {
    return this.formId;
  }
  public set FormId(value: number) {
    this.formId = value;
  }

  private formStatus: string;
  public get FormStatus(): string {
    return this.formStatus;
  }
  public set FormStatus(value: string) {
    this.formStatus = value;
  }

  private isProcessingData: boolean;
  public get IsProcessingData(): boolean {
    return this.isProcessingData;
  }
  public set IsProcessingData(value: boolean) {
    this.isProcessingData = value;
  }

  private isMarketingConsent: boolean;
  public get IsMarketingConsent(): boolean {
    return this.isMarketingConsent;
  }
  public set IsMarketingConsent(value: boolean) {
    this.isMarketingConsent = value;
  }

  private personType: string;
  public get PersonType(): string {
    return this.personType;
  }
  public set PersonType(value: string) {
    this.personType = value;
  }

  static override backBuild(data: IResponsePendingRequestV2) {
    const formateando = new ListPendingRequest();
    if (data) {
      formateando.DateRequest =
        _moment(data.dateInvitation).format('DD/MM/YYYY') ?? '-';
      formateando.LegalName = data.legalName ?? '-'; // _moment(this.message.createdAt).format('DD/MM/YYYY')
      formateando.HomologationId = data.homologationId;
      formateando.HasAgreed = data.hasAgreed;
      formateando.NationalID = data.nationalId;
      formateando.ConditionDescription = data.conditionName;
      formateando.IsPayed = data.isPayed;
      formateando.IsPaymentVerified = data.isPaymentVerified;
      formateando.Country = data.country;
      formateando.CountryCustomer = data.countryCustomer;
      formateando.NameSupplier = data.supplierName;
      formateando.NameClient = data.legalName;
      formateando.LanguageProcess = data.language;
      formateando.ConditionId = data.conditionId;
      formateando.FormId = data?.formId;
      formateando.OfficeId = data?.officeId;
      formateando.IsProcessingData = data.isProcessingData;
      formateando.IsMarketingConsent = data.isMarketingConsent;
      formateando.PersonType = data.personType === 'Natural' ? 'PN' : 'PJ';
      formateando.FormStatus = data?.formStatus;

      //formateando.OfficeId = data?.userEntity?.id || data.user;
      //formateando.LanguageProcess = data.homologation.language;
      // data.homologation.form.id
    }
    return formateando;
  }
}
