import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[cofaceOnlyLetters]',
})
export class OnlyLettersDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInputChange(event: any): void {
    const initialValue: string = this.el.nativeElement.value;

    // Filter out non-letter characters
    this.el.nativeElement.value = initialValue.replace(/[^a-zA-Z ]/g, '');

    // If the initial value was modified, prevent the change from propagating further
    if (initialValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
