export enum HOMOLOGATION_CONDITION {
  NOTIFICADO = 1,
  RECHAZADO = 2,
  SIN_RESPUESTA = 3,
  PENDIENTE_PAGO = 4,
  PENDIENTE_INFORMACION = 5,
  CALIFICACION_VENCIDA = 8,
  REPORTE_ELABORACION = 6,
  CALIFICACION_FINALIZADA = 7,
  RECALIFICACION = 9,
  PAGO_VALIDADO = 14,
}

export enum LANGUAGE_ID {
  PORTUGUES = 'pt',
  SPANISH = 'es',
  ENGLISH = 'en',
}

// [
//   { id: 1, name: 'NOTIFICADO' },
//   { id: 2, name: 'RECHAZADO' },
//   { id: 3, name: 'SIN RESPUESTA' },
//   { id: 4, name: 'PENDIENTE DE PAGO' },
//   { id: 5, name: 'PENDIENTE DE INFORMACION' },
//   { id: 6, name: 'REPORTES EN ELABORACION' },
//   { id: 7, name: 'CALIFICACION FINALIZADA' },
//   { id: 8, name: 'CALIFICACION VENCIDA' },
//   { id: 9, name: 'RECALIFICACION' },
//   { id: 10, name: 'IMPOSIBLE CONTACTAR' },
//   { id: 11, name: 'CALIFICACIÓN CANCELADA' },
// ];
// TODO: ENUMS CONDITION
