import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericRequestConfig } from 'projects/ui-coface/src/app/commons/model/request.config';
import { ModalService } from 'projects/ui-coface/src/app/commons/services/dialog.service';
import { environment } from 'projects/ui-coface/src/environments/environment';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InformacionBasicaEndpoint } from '../http/informacion-basica.endpoint';

@Injectable({
  providedIn: 'root',
})
export class InformacionBasicaOperation {
  constructor(
    private informacionBasicaEndpoint: InformacionBasicaEndpoint,
    private dialog: ModalService
  ) {}

  customerSupplierDetails$(homologationId: number) {
    const req = new GenericRequestConfig();
    req.url = `${environment.urlBaseEndpointV2}/customers/supplier-details`;
    req.params = new HttpParams().set('homologation', btoa(homologationId.toString()));
    return this.informacionBasicaEndpoint
      .customerSupplierDetails$(req)
      .pipe(map((res) => res.data.information));
  }
}
