import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { TermConditionOperation } from 'projects/ui-coface/src/app/commons/operations/term-condition.operation';
import { ModalService } from 'projects/ui-coface/src/app/commons/services/dialog.service';
import { SessionService } from 'projects/ui-coface/src/app/commons/services/session.service';
import {
  IDescriptionWelcomeModal,
  IPriceRate,
} from 'projects/ui-coface/src/app/modules/homologacion/commons/homologacion-interface';
import { HomologacionOperation } from 'projects/ui-coface/src/app/modules/homologacion/commons/homologacion-operation';
import { ListPendingRequest } from 'projects/ui-coface/src/app/modules/homologacion/pending-request/commons/model/pending-request.model';
import { PendingRequestOperation } from 'projects/ui-coface/src/app/modules/homologacion/pending-request/commons/operation/pending-request.operation';
import { environment } from 'projects/ui-coface/src/environments/environment';
import { catchError, map, switchMap, throwError } from 'rxjs';
import { EndProcessComponent } from '../end-process/end-process.component';
import { SelectLanguageComponent } from '../select-language/select-language.component';

import { TermsAndConditionsPersonLegalComponent } from '../terms-and-conditions-by-country/terms-and-conditions-person-legal/terms-and-conditions-person-legal.component';
import { TermsAndConditionsPersonNaturalComponent } from '../terms-and-conditions-by-country/terms-and-conditions-person-natural/terms-and-conditions-person-natural.component';

@Component({
  selector: 'coface-welcome-provider',
  templateUrl: './welcome-provider.component.html',
  styleUrls: ['./welcome-provider.component.scss'],
})
export class WelcomeProviderComponent implements OnInit, OnDestroy {
  termCond = new UntypedFormControl(null, [Validators.required]);
  information: IDescriptionWelcomeModal;
  dataSupplier: any;
  nameSupplier: string;
  getPriceByCountry: IPriceRate;
  authRate: boolean = true;
  countryOffice: string;
  idHomologation: number;
  isSelectedPersonNatural: boolean = false;
  isSelectedPersonJuridic: boolean = false;

  formId: number;

  constructor(
    private dialogService: DialogService,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private termConditionOperation: TermConditionOperation,
    private pendingRequestOperation: PendingRequestOperation,
    private homologationOperation: HomologacionOperation
  ) {}

  ngOnInit(): void {
    let info = this.dialogConfig.data as ListPendingRequest;


    this.idHomologation = info.HomologationId;
    this.formId = info.FormId;
    this.dataSupplier = info;
    this.homologationOperation
      .getNamePresentationLetter(info.HomologationId)
      .subscribe((data) => {
        this.nameSupplier = this.pendingRequestOperation.nameSupplier;
        this.information = data.data;
      });
    this.getPriceRate();
    this.getInfoDetail();
  }

  ngOnDestroy(): void {}

  public getInfoDetail() {
    const modalData = this.dialogConfig.data as ListPendingRequest;

    this.countryOffice = modalData.Country?.name;
  }
  public getPriceRate() {
    let info = this.dialogConfig.data as ListPendingRequest;
    this.homologationOperation
      .getPriceRateByCountry(info.Country.id, info.OfficeId)
      .subscribe((data) => (this.getPriceByCountry = data.data));
  }

  public onOpenModalTermCondition() {
    let typeModal;
    // Preguntar si van a ir los terminos y condiciones para los paises
    // switch (this.countryOffice) {
    //   case 'Ecuador':
    //     typeModal = TermsAndConditionsEcuadorComponent;
    //     break;
    //   case 'Brazil':
    //     typeModal = TermsAndConditionsBrazilComponent;
    //     break;
    //   case 'Colombia':
    //     typeModal = TermsAndConditionsColombiaComponent;
    //     break;
    //   default:
    //     typeModal = TermsAndConditionsEcuadorComponent;
    //     break;
    // }

    if (!this.isSelectedPersonNatural && !this.isSelectedPersonJuridic) {
      return;
    }

    if (this.isSelectedPersonNatural) {
      typeModal = TermsAndConditionsPersonNaturalComponent;
    } else if (this.isSelectedPersonJuridic) {
      typeModal = TermsAndConditionsPersonLegalComponent;
    }

    const ref = this.dialogService.open(typeModal, {
      showHeader: false,
      width: '65%',
      dismissableMask: true,
      transitionOptions: '400ms cubic-bezier(0.25, 0.8, 0.25, 1)',
      contentStyle: { 'border-radius': '15px' },
      baseZIndex: 10000,
    });

    ref.onClose.subscribe((data) => {
      // SI ACEPTO TODOS LOS TERMINOS DEL MODAL
      if (data === 'COMPLETE') {
        this.homologationOperation
          .homologationEntity(this.dialogConfig.data.homologationId)
          .subscribe((data) => {
            if (!data.language?.name) {
              const refe = this.dialogService.open(SelectLanguageComponent, {
                showHeader: false,
                width: '35%',
                dismissableMask: false,
                transitionOptions: '400ms cubic-bezier(0.25, 0.8, 0.25, 1)',
                contentStyle: { 'border-radius': '15px' },
                baseZIndex: 10000,
                data: this.idHomologation,
              });
              refe.onClose.subscribe((data) => {
                if (data === 'COMPLETE') {
                  this.termCond.setValue(true);
                }
              });
            } else {
              this.termCond.setValue(true);
            }
          });
      }
    });
  }

  onAcceptAndContinueProccess() {
    let info = this.dialogConfig.data as ListPendingRequest;
    // ACTUALIZA LOS TERMINOS Y CONDICIONES, LUEGO INICIA EL PROCESO DE HOMOLOGACION ACTUALIZANDO SU CONDICION A 4
    this.termConditionOperation
      .termConditionAccept$(info.HomologationId, this.formId)
      .pipe(
        switchMap((_) => {
          return this.pendingRequestOperation.initHomologation$(
            info.HomologationId
          );
        })
      )
      .subscribe((res) => {
        if (res) {
          this.dialogRef.close('COMPLETE_TRANSACTION');
        }
      });
  }

  onDeniedContinueProcess() {
    let info = this.dialogConfig.data as ListPendingRequest;
    const ref = this.dialogService.open(EndProcessComponent, {
      showHeader: false,
      width: '35%',
      dismissableMask: true,
      transitionOptions: '400ms cubic-bezier(0.25, 0.8, 0.25, 1)',
      contentStyle: { 'border-radius': '15px' },
      baseZIndex: 10000,
      data: info,
    });
    this.dialogRef.close();
  }

  downloadFile() {
    window.open(
      `${environment.urlBaseEndpoint}/attachments/download/${this.information.presentationLetterId}`
    );
  }

  changeFirstRate(event) {
    if (event.target.value === 'especifico') {
      this.authRate = false;
    } else {
      this.authRate = false;
    }
  }

  selectedPersonNatural() {
    this.isSelectedPersonNatural = !this.isSelectedPersonNatural;
    if (this.isSelectedPersonNatural) {
      this.isSelectedPersonJuridic = false;
    }
  }

  selectedPersonjuridic() {
    this.isSelectedPersonJuridic = !this.isSelectedPersonJuridic;
    if (this.isSelectedPersonJuridic) {
      this.isSelectedPersonNatural = false;
    }
  }

  public closeModal() {
    this.dialogRef.close();
  }
}
