import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, take } from 'rxjs';
import { LanguageService } from './core/services/language.service';
import { LANGUAGE_ID } from 'projects/ui-coface/src/app/commons/enums/condition.enum';
import { RedirectService } from './commons/services/redirect.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'ui-coface';
  subscription: Subscription;
  constructor(
    private translate: TranslateService,
    private languageService: LanguageService,
    private redirectService: RedirectService
  ) {}

  ngOnInit(): void {
    console.log('FORMULARIOS UAT - ANGULAR V17 - 24/01/2025');
    // {
    //   "id": 9,
    //   "name": "Contraseñas",
    //   "path": "/contrasenas",
    //   "image": "configuration.svg",
    //   "rol": [
    //     "SUPERUSER",
    //     "ADMINISTRATOR"
    //   ]
    // }

    this.listeningLanguage();
  }

  private listeningLanguage() {
    let useLanguage;

    this.subscription = this.languageService.getMessage().subscribe((data) => {
      switch (data.idlanguage) {
        case LANGUAGE_ID.SPANISH:
          useLanguage = 'es';
          this.translate.use(useLanguage);
          break;
        case LANGUAGE_ID.PORTUGUES:
          useLanguage = 'pt';
          this.translate.use(useLanguage);
          break;
        case LANGUAGE_ID.ENGLISH:
          useLanguage = 'en';
          this.translate.use(useLanguage);
          break;
        default:
          break;
      }
    });
  }
}
