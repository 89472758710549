import { CofaceBuilder } from 'projects/ui-coface/src/app/commons/model/coface.builder';
import {
  Homologation,
  IDetailsBandejaTrabajo,
  IResponseSupplier,
  UserEntity,
} from '../interface/provide.paginator';
import _moment from 'moment';
import { IDetail } from 'projects/ui-coface/src/app/commons/interface/paginator-table.interface';
import { MODULE_COLLAPSE } from 'projects/ui-coface/src/app/commons/enums/module-collapse.enum';
import { EUnlockModule } from 'projects/ui-coface/src/app/commons/enums/unlock-module.enum';
import { IListSupplierByRole } from '../interface/supplier-by-role.interface';

export class WalletProviderNew extends CofaceBuilder {
  private homologation: Homologation;
  public get Homologation(): Homologation {
    return this.homologation;
  }
  public set Homologation(value: Homologation) {
    this.homologation = value;
  }
  private userEntity: UserEntity;
  public get UserEntity(): UserEntity {
    return this.userEntity;
  }
  public set UserEntity(value: UserEntity) {
    this.userEntity = value;
  }

  private homologationId: number;
  public get HomologationId(): number {
    return this.homologationId;
  }
  public set HomologationId(value: number) {
    this.homologationId = value;
  }

  private legalNameSupplier: string;
  public get LegalNameSupplier(): string {
    return this.legalNameSupplier;
  }
  public set LegalNameSupplier(value: string) {
    this.legalNameSupplier = value;
  }
  private nationalIdSupplier: string;
  public get NationalIdSupplier(): string {
    return this.nationalIdSupplier;
  }
  public set NationalIdSupplier(value: string) {
    this.nationalIdSupplier = value;
  }
  private id: number;
  public get Id(): number {
    return this.id;
  }
  public set Id(value: number) {
    this.id = value;
  }

  private homologationConditionId: number;
  public get HomologationConditionId(): number {
    return this.homologationConditionId;
  }
  public set HomologationConditionId(value: number) {
    this.homologationConditionId = value;
  }

  private homologationCondition: string;
  public get HomologationCondition(): string {
    return this.homologationCondition;
  }
  public set HomologationCondition(value: string) {
    this.homologationCondition = value;
  }

  private nameCustomer: string;
  public get NameCustomer(): string {
    return this.nameCustomer;
  }
  public set NameCustomer(value: string) {
    this.nameCustomer = value;
  }

  private detail: IDetail<IDetailsBandejaTrabajo>;
  public get Detail(): IDetail<IDetailsBandejaTrabajo> {
    return this.detail;
  }
  public set Detail(value: IDetail<IDetailsBandejaTrabajo>) {
    this.detail = value;
  }

  private companyId: string;

  public get CompanyId(): string {
    return this.companyId;
  }

  public set CompanyId(value: string) {
    this.companyId = value;
  }

  private supplierId: number;

  public get SupplierId(): number {
    return this.supplierId;
  }

  public set SupplierId(value: number) {
    this.supplierId = value;
  }

  // private id: string;
  // public get Id(): string {
  //   return this.id;
  // }
  // public set Id(value: string) {
  //   this.id = value;
  // }

  static override backBuild(data: IListSupplierByRole) {
    const content = new WalletProviderNew();

    if (data) {
      content.Id = data.id;
      content.HomologationId = data.id;
      content.LegalNameSupplier = data.supplierName;
      content.NationalIdSupplier = data.nationalId;
      content.HomologationConditionId = data.conditionId!;
      content.HomologationCondition = data.conditionName;
      content;
      content.NameCustomer = data.customerName;
      content.Detail = {
        moduleType: MODULE_COLLAPSE.WALLET_PROVIDER,
        dataDetail: {
          activity: data.economicActivity ?? '-------',
          validity: data.validity ?? '-------',
          lastCalification: data.lastDate ?? '-------',
          dateProcessInit: data.validity ?? '-------',
          dateProcessEnd: data.lastDate ?? '-------',
          score: data.score ?? '-------',
          classification: data.classification ?? '-------',
          analystName: data.analystName ?? '-------',
          customerName: data.customerName ?? '-------',
          homologationId: data.id!,
          legalNameSupplier: data.supplierName ?? '-------',
          validStatusHomologation: data.conditionId.toString() !== '7' ? true : false,
          // status: data.status ?? '-',
          // data.details.lastCalification ?? '-------',
          // economyActivitie: 'Código 151',
          // lateGradeQualification: '------------',
          // lastQualificationDate: '22/06/2021',
          // qualificationDate: '22/06/2021',
        },
      };
      content.CompanyId = data.supplierName ?? '-------';
      content.SupplierId = data.supplierId ?? '-------';
      content['viewMore'] = 'COLLAPSE';
      content['blockedState'] =
        data?.status === 'ACTIVE' ? 'Activo' : 'Bloqueado';
      content['blockedStatebtn'] = 'BLOCKED_STATE';
      content['nameToUnlock'] = data?.supplierName;
      content['unlockModule'] = EUnlockModule.MANAGER_SUPPLIER_WALLET;
      content['supplierId'] = data?.supplierId
    }

    return content;
  }
}
