<!-- {{valuev2 }} -->
<div class="relative" #autoCompleteTemplate>
  <input
    type="text"
    (input)="onChangeInput($event)"
    (click)="onFocusInput()"
    [placeholder]="placeholder"
    [value]="valuev2"
    class="w-full px-5 py-3 rounded-3xl outline-none text-sm border "
    [ngClass]="{'shadow-lg shadow-gray-50-500/40 placeholder': shadow}"
    #refInput
  />
  @if(showIcon) {
  <img
    class="absolute top-3 right-3"
    src="assets/img/upload/lens.svg"
    width="20px"
    height="20px"
    alt="Search icon"
  />
  }
  <div
    *ngIf="showSuggestions"
    class="absolute z-10 w-full bg-white border rounded mt-1 max-h-60 overflow-y-auto h-40"
  >
    <div class="flex mt-4 justify-center">
      <span
        class="text-center text-gray-500 text-xs tracking-wide"
        *ngIf="
          refInput.value.length >= 3 && suggestions?.length === 0 && !loading
        "
      >
        NO EXISTEN COINCIDENCIAS <br />
      </span>
      <span
        class="text-center text-gray-500 text-xs tracking-wide"
        *ngIf="
          refInput.value.length <= lenghtRequired &&
          !loading &&
          !suggestions?.length
        "
      >
        Ingresa al menos 3 digitos para iniciar la búsqueda
        <br />
      </span>
    </div>

    <div
      class="flex justify-center items-center h-10"
      *ngIf="loading && refInput.value"
    >
      <svg
        class="animate-spin h-10 w-8 text-coface-primary"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>

    <div
      *ngFor="let suggestion of suggestions"
      (click)="selectSuggestion(suggestion)"
      class="px-4 py-2 cursor-pointer hover:bg-gray-100"
    >
      <span class="text-left text-gray-500 text-xs tracking-wide">
        {{ suggestion.name }}
      </span>
    </div>
  </div>
</div>
