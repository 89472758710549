export enum StoreName {
  // CUSTOMER_ID = 'CUSTOMER_ID',
  DASHBOARD_GENERAL_DATA = 'DASHBOARD_GENERAL_DATA',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  //
  FORM_ID = 'FORM_ID', // FORMULARIOS EN LINEA ID NECESARIO
  FORM_STATUS = 'FORM_STATUS', // FORMULARIOS EN LINEA ESTADO DE LA FORMULARIO
  HOMOLOGATION_CONDITIONS = 'HOMOLOGATION_CONDITION', // CONDITION DE LA HOMOLOGACION
  HOMLOGATION_ID = 'HOMLOGATION_ID', // ID DE LA HOMOLOGACION
  CUSTOMER_ID = 'CUSTOMER_ID', // ID DEL CLIENTE
  CUSTOMER_COMPANY_NAME = 'CUSTOMER_COMPANY_NAME', // NOMBRE DE LA COMPANIA DEL CLIENTE
  PERSON_TYPE = 'PERSON_TYPE', // PERSONA NATURAL O JURIDICA PROVEEDOR
  SUPPLIER_ID = 'SUPPLIER_ID', // ID DEL PROVEEDOR
}
