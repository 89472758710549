import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/ui-coface/src/environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  private subject = new Subject<any>();

  constructor(private _http: HttpClient) {}

  sendMessage(idLanguage: string) {
    this.subject.next({ idlanguage: idLanguage });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  /**update platform language */
  updateLanguage(code: string, obje) {
    let obj = {};
    const params = new HttpParams().set('languageCode', code);
    const url = `${environment.urlBaseEndpoint}/account/settings/language`;
    return this._http.patch(url, obj, { params: params });
  }

  /**update process language provider*/
  updateProcessLanguage(code: string, idHomologation: number) {
    let obj = {};
    const params = new HttpParams()
      .set('languageCode', code)
      .set('operationId', idHomologation);
    const url = `${environment.urlBaseEndpoint}/account/settings/language`;
    return this._http.patch(url, obj, { params: params });
  }
}
