import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'coface-error-online-form',
  templateUrl: './error-online-form.component.html',
  styleUrl: './error-online-form.component.scss',
})
export class ErrorOnlineFormComponent implements OnInit {
  // public formUrls = [
  //   '/forms/progress-bar/:id',
  //   '/forms/:id/general-info',
  //   '/forms/:id/activity',
  //   '/forms/:id/reference',
  //   '/forms/:id/financial-structure',
  //   '/forms/:id/infrastructure',
  //   '/forms/:id/humanTalent',
  //   '/forms/:id/machinery-equipments',
  //   '/forms/:id/inventory',
  //   '/forms/:id/quality',
  //   '/forms/:id/market',
  //   '/forms/:id/customer-service',
  //   '/forms/:id/warranty',
  //   '/forms/:id/prices-sales',
  //   '/forms/:id/legal-observation',
  //   '/forms/:id/enabling-documents',
  // ];

  public urlMappings = [
    {
      urlPattern: '^/forms/progress-bar/(\\d+|null)$',
      label: 'Información General',
    },
    {
      urlPattern: '^/forms/(\\d+|null)/general-info$',
      label: 'Información General',
    },
    {
      urlPattern: '^/forms/(\\d+|null)/activities$',
      label: 'Perfil Empresarial / Actividad',
    },
    {
      urlPattern: '^/forms/(\\d+|null)/reference$',
      label: 'Perfil Empresarial / Referencias',
    },
    {
      urlPattern: '^/forms/(\\d+|null)/financial-structure$',
      label: 'Perfil Empresarial / Estructura Financiera',
    },
    {
      urlPattern: '^/forms/(\\d+|null)/infrastructure$',
      label: 'Capacidad Operativa / Infraestructura',
    },
    {
      urlPattern: '^/forms/(\\d+|null)/humanTalent$',
      label: 'Capacidad Operativa / Talento Humano',
    },
    {
      urlPattern: '^/forms/(\\d+|null)/machinery-equipments$',
      label: 'Capacidad Operativa / Maquinaria y Equipos',
    },
    {
      urlPattern: '^/forms/(\\d+|null)/inventory$',
      label: 'Capacidad Operativa / Inventarios',
    },
    { urlPattern: '^/forms/(\\d+|null)/quality$', label: 'Gestión de Calidad' },
    {
      urlPattern: '^/forms/(\\d+|null)/market$',
      label: 'Gestión Comercial / Mercado',
    },
    {
      urlPattern: '^/forms/(\\d+|null)/customer-service$',
      label: 'Gestión Comercial / Servicio al Cliente',
    },
    {
      urlPattern: '^/forms/(\\d+|null)/prices-sales$',
      label: 'Gestión Comercial / Precios y Ventas',
    },
    {
      urlPattern: '^/forms/(\\d+|null)/legal-observation$',
      label: 'Observaciones Legales',
    },
    {
      urlPattern: '^/forms/(\\d+|null)/enabling-documents$',
      label: 'Documentos Habilitantes',
    },
  ];

  public label: string;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    const data = this.dialogConfig.data as HttpErrorResponse;
    const url = data.url;
    let startIndex = url!.indexOf('/forms/');
    let cutUrl = url?.substring(startIndex);
    let formUrl: string | undefined;
    console.log(cutUrl);
    
    // Recorrer el array y buscar el patrón que coincida con la URL
    this.urlMappings.forEach((mapping) => {
      const regex = new RegExp(mapping.urlPattern); // Crear la expresión regular con la clave

      if (regex.test(cutUrl!)) {
        formUrl = mapping.label; // Asignar el valor cuando haya una coincidencia
      }
    });

    this.label = formUrl || '';
  }

  closeModal(){
    this.ref.close();
  }
}
