import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import {
  ILanguages,
  IMonthTerms,
} from 'projects/ui-coface/src/app/auth/commons/interface/auth.interface';
import { ICountries } from 'projects/ui-coface/src/app/commons/interface/menu.interface';

import { Parameter } from 'projects/ui-coface/src/app/commons/model/parameter.model';
import { SessionService } from 'projects/ui-coface/src/app/commons/services/session.service';
import { DropdawnService } from 'projects/ui-coface/src/app/core/services/dropdawn.service';
import { CustomerEndpoint } from 'projects/ui-coface/src/app/modules/customer/commons/endpoints/customer.endpoint';
import { FormCustomer } from 'projects/ui-coface/src/app/modules/customer/commons/interface/customer.interface';
import { CustomerList } from 'projects/ui-coface/src/app/modules/customer/commons/models/customer-list';
import { CustomerOperation } from 'projects/ui-coface/src/app/modules/customer/commons/operations/customer.operations';
import { SingleUploadPresenter } from 'projects/ui-coface/src/app/modules/customer/page/single-upload/single-upload.presenter';
import { SuccessfulComponent } from '../successful/successful.component';
@Component({
  selector: 'coface-edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.scss'],
  providers: [SingleUploadPresenter, CustomerOperation],
})
export class EditCustomerComponent implements OnInit {
  itemsQuizzes = [];
  public dataListAccountManager: Array<Parameter>;
  itemsDeliveryTerms: IMonthTerms[] = [];
  itemsCountries: ICountries[] = [];
  fileLoaded: string;
  fileLoadedTwo: string;
  showFileLoaded: boolean = false;
  showFileLoadedTwo: boolean = false;
  title1: string = 'País';
  title2: string = 'Tipo de cuestionario Base';
  title3: string = 'Periodo de validez del Reporte';
  title4: string = 'Account Manager';
  title5: string = 'Idioma del Cliente';

  showModal: boolean = false;
  form: boolean = false;
  // formRange: FormGroup;
  loaderBtnEvnt: boolean = false;
  messageError: boolean = false;
  textSendSucces: string = 'Cliente actualizado con éxito';
  configData: CustomerList;
  itemsLanguages: ILanguages[] = [];
  public filteredCountries: Array<Parameter> = [];
  public currencyList: Array<unknown> = [];

  constructor(
    private _select: DropdawnService,
    public session: SessionService,
    public singleUploadPresenter: SingleUploadPresenter,
    private dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public customerOperation: CustomerOperation,
    private cdr: ChangeDetectorRef,
    private customerEndpoint: CustomerEndpoint
  ) {}

  ngOnInit(): void {
    this.currencyList = this._select.currencyList;
    this.itemsLanguages = this._select.dataListLanguages;
    this.itemsDeliveryTerms = this._select.dataPeriodOfValidity;
    this.itemsQuizzes = this._select.dataQuestionnaireType;
    this.itemsCountries = this._select.dataCountries;
    this.filteredCountries = this._select.dataCountriesDashboard;

    this.customerOperation.getAccountManagers$().subscribe((data) => {
      this.dataListAccountManager = data;
      // this.dataListAccountManager.office
    });
    this.patchValue();
  }

  patchValue() {
    let config = this.config.data as CustomerList;
    this.configData = this.config.data as CustomerList;
    // golpear servicio
    this.customerEndpoint.getMyManager$(config.Id).subscribe((data) => {
      const filePresentationLetters =
        config.Detail.dataDetail?.attachment?.filter(
          (x) => x?.folderName === 'PRESENTATION_LETTERS'
        );
      const fileTwo = config.Detail.dataDetail.attachment?.filter(
        (x) => x?.folderName === 'CUSTOM_FORMS'
      );

      this.showFileLoaded = filePresentationLetters.length > 0;
      this.fileLoaded = filePresentationLetters[0]?.fileName;

      this.showFileLoadedTwo = fileTwo?.length > 0;
      this.fileLoadedTwo = fileTwo[0]?.fileName;

      this.singleUploadPresenter.patchValue({
        legalCode: config?.NationalId,
        denomination: config?.NameCompany,
        tradeName: config?.NameTrade,
        address: config?.Detail.dataDetail.address,
        phoneNumber: config?.PhoneNumber,
        email: config?.Detail.dataDetail.email,
        firstname: config?.Detail.dataDetail.name,
        lastname: config?.Detail.dataDetail.lastname,
        countryCode: config?.CountryId.toString(),
        quizId: config?.Detail.dataDetail.tipoCuestionarioBaseFile?.toString(),
        expirationTerm: config?.Detail.dataDetail.periodoValidezFile,
        quizLetter: '',
        coverLetter: '',
        idCompany: config?.IdCompany,
        idScore: config?.IdScore,
        idUser: config?.Id,
        reportSalePrice: config.ReportSalePrice,
        reportCurrency: config.ReportCurrency.toString(),
        generalPrice: config.GeneralCurrencyPrice,
        generalCurrency: config.GeneralCurrency.toString(),
        analystId: data.data?.id.toString(),
        language: config.Detail.dataDetail.languageId,
      });

      this.singleUploadPresenter.range.patchValue(
        config?.Detail.dataDetail.scorePolicy!
      );
      // this.validateFile = true;
      this.singleUploadPresenter.Form.controls['coverLetter'].setValidators([]);
      this.singleUploadPresenter.Form.controls['range'].setAsyncValidators([]);
      this.singleUploadPresenter.Form.controls[
        'range'
      ].updateValueAndValidity();
      this.singleUploadPresenter.Form.controls[
        'coverLetter'
      ].updateValueAndValidity();
    });
  }

  public searchTextCountries(event: string) {
    this.filteredCountries = this._select.dataCountriesDashboard.filter((x) =>
      x.Name.toLowerCase().includes(event.toLowerCase())
    );
  }

  changeFileEvent() {
    if (this.showFileLoaded) {
      this.showFileLoaded = !this.showFileLoaded;
      this.singleUploadPresenter.coverLetter.reset('');
    }
  }

  changeFileEventTwo() {
    if (this.showFileLoadedTwo) {
      this.showFileLoadedTwo = !this.showFileLoadedTwo;
      this.singleUploadPresenter.quizLetter.reset('');
    }
  }

  onUpdateCustomer() {
    // console.log(body);
    // if (this.singleUploadPresenter.Form.valid) {
    this.loaderBtnEvnt = true;
    this.customerOperation
      .updateCustomers$(
        this.singleUploadPresenter.Form.value as FormCustomer.ICustomer,
        this.configData
      )
      .subscribe((data) => {
        if (data) {
          this.ref.close();
          this.dialogService.open(SuccessfulComponent, {
            data: this.textSendSucces,
            showHeader: false,
            width: '35%',
            dismissableMask: true,
            contentStyle: { 'border-radius': '15px' },
            baseZIndex: 10000,
          });
          this.loaderBtnEvnt = false;
        }
      })
      .add(() => {
        this.loaderBtnEvnt = false;
      });
  }
}
